var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.canSave()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"text","label":"Nombre de moneda","dense":"","outlined":"","rules":_vm.rules.required.concat([
              _vm.rules.requiredTrim(_vm.monedaNom), 
              _vm.rules.maxLength(_vm.monedaNom, 100)
              ])},model:{value:(_vm.monedaNom),callback:function ($$v) {_vm.monedaNom=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"monedaNom"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-menu',{ref:"vigencia-desde",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia desde","append-icon":_vm.calendarIcon,"autocomplete":"not","hint":"Formato DD/MM/AAAA","outlined":"","dense":"","clearable":"","rules":_vm.vigenciaDesde != null? 
                    _vm.rules.validDate.concat(
                      new Date(_vm.parseDateToIso(_vm.vigenciaDesdeSelected)) <=
                        new Date(_vm.parseDateToIso(_vm.vigenciaHastaSelected)) ||
                        'Formato incorrecto'
                    ) : []},on:{"blur":function($event){_vm.vigenciaDesde = _vm.parseDateToIso(_vm.vigenciaDesdeSelected)}},model:{value:(_vm.vigenciaDesdeSelected),callback:function ($$v) {_vm.vigenciaDesdeSelected=$$v},expression:"vigenciaDesdeSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuVigenciaDesde),callback:function ($$v) {_vm.menuVigenciaDesde=$$v},expression:"menuVigenciaDesde"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.vigenciaDesdeSelected = _vm.formatDate(_vm.vigenciaDesde)},"input":function($event){_vm.menuVigenciaDesde = false}},model:{value:(_vm.vigenciaDesde),callback:function ($$v) {_vm.vigenciaDesde=$$v},expression:"vigenciaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-menu',{ref:"vigencia-hasta",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia hasta","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","outlined":"","autocomplete":"not","dense":"","clearable":"","rules":_vm.vigenciaHasta != null? 
                    _vm.rules.validDate.concat(
                      new Date(_vm.parseDateToIso(_vm.vigenciaDesdeSelected)) <=
                        new Date(_vm.parseDateToIso(_vm.vigenciaHastaSelected)) ||
                        'Formato incorrecto'
                    ) : []},on:{"blur":function($event){_vm.vigenciaHasta = _vm.parseDateToIso(_vm.vigenciaHastaSelected)}},model:{value:(_vm.vigenciaHastaSelected),callback:function ($$v) {_vm.vigenciaHastaSelected=$$v},expression:"vigenciaHastaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuVigenciaHasta),callback:function ($$v) {_vm.menuVigenciaHasta=$$v},expression:"menuVigenciaHasta"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.vigenciaHastaSelected = _vm.formatDate(_vm.vigenciaHasta)},"input":function($event){_vm.menuVigenciaHasta = false}},model:{value:(_vm.vigenciaHasta),callback:function ($$v) {_vm.vigenciaHasta=$$v},expression:"vigenciaHasta"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0 my-n5 pb-4",attrs:{"cols":"6","md":"6"}},[_c('v-switch',{attrs:{"label":"Habilitado para carga"},model:{value:(_vm.habCarga),callback:function ($$v) {_vm.habCarga=$$v},expression:"habCarga"}})],1),_c('v-col',{staticClass:"py-0 my-n5 pb-4",attrs:{"cols":"6","md":"6"}},[_c('v-switch',{attrs:{"label":"Habilitado para listado"},model:{value:(_vm.habListado),callback:function ($$v) {_vm.habListado=$$v},expression:"habListado"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }