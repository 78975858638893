<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="monedasItems"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
          item-key="monedaId"
          show-expand
          :expanded.sync="expanded"
        >
          <!-- Expansion de usuario -->
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{
                    isExpanded ? "mdi-account-check" : "mdi-account"
                  }}</v-icon
                >
              </template>
              <span>Usuarios</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Fecha alta:</strong>
              {{ item.fecAlta }}
              <strong style="padding-left: 80px">Usuario alta:</strong>
              {{ item.usuAlta }}
              <strong style="padding-left: 80px">Fecha modificación:</strong>
              {{ item.fecModi }}
              <strong style="padding-left: 80px">Usuario modificación:</strong>
              {{ item.usuModi }}
            </td>
          </template>
          <template v-slot:[`item.habCarga`]="{ item }">
            <v-icon v-if="item.habCarga" small color="primary">
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:[`item.habListado`]="{ item }">
            <v-icon v-if="item.habListado" small color="primary">
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="7" md="6">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" md="3" align="end" class="mr-n6">
                  <v-btn
                    color="primary"
                    @click="goToCotizacionesMonedas()"
                    class="to-right"
                  >
                    Cotizaciones
                  </v-btn>
                </v-col>
                <v-col cols="3" align="end" v-if="canCreate">
                  <v-btn
                    color="primary"
                    @click="openModalEdit()"
                    class="to-right"
                  >
                    Nueva Moneda
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalEdit(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar moneda</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteMonedaModal(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar moneda</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="isOpenModalEdit"
      v-model="isOpenModalEdit"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditMoneda
        :editMonedaItem="editMonedaItem"
        @closeAndReload="closeAndReload"
      ></EditMoneda>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :isLoading="loadingBtnDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditMoneda from "@/components/modules/administracion-sistema/monedas/EditMoneda.vue";
export default {
  name: "MonedasGeneral",
  components: { PageHeader, DeleteDialog, GoBackBtn, Ayuda, EditMoneda },
  data: () => ({
    routeToGo: "ConfiguracionAdmSistema",
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    checkIcon: enums.icons.CHECK_OUTLINE,
    title: "Monedas",
    titleDelete: "Desea eliminar la moneda?",
    search: "",
    optionCode: enums.webSiteOptions.MONEDAS,
    idToDelete: null,
    editMonedaItem: null,
    expanded: [],
    monedasItems: [],
    headers: [
      {
        text: "Moneda",
        value: "monedaNom",
        sortable: false,
      },
      {
        text: "Vigencia desde",
        value: "vigDesde",
        sortable: false,
        align: "center",
      },
      {
        text: "Vigencia hasta",
        value: "vigHasta",
        sortable: false,
        align: "center",
      },
      {
        text: "Habilitada carga",
        value: "habCarga",
        sortable: false,
        align: "center",
      },
      {
        text: "Habilitado listado",
        value: "habListado",
        sortable: false,
        align: "center",
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        align: "end",
      },
    ],
    allowedActions: null,
    idToDelete: null,
    isLoading: false,
    showDeleteModal: false,
    loadingBtnDelete: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    isOpenModalEdit: false,
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setPermisos();
    this.loadMonedas();
  },
  methods: {
    ...mapActions({
      getMonedas: "AdministracionSistema/getMonedas",
      deleteMoneda: "AdministracionSistema/deleteMoneda",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_MONEDA:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_MONEDA:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVA_MONEDA:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadMonedas() {
      this.isLoading = true;
      const data = await this.getMonedas();
      this.monedasItems = data;
      this.isLoading = false;
    },
    deleteMonedaModal(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.monedaId;
    },
    async confirmDelete() {
      this.loadingBtnDelete = true;
      try {
        const response = await this.deleteMoneda(this.idToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({
            type: "success",
            message: "Moneda eliminada con exito",
          });
          this.loadingBtnDelete = false;
          this.loadMonedas();
        }
      } catch (error) {
        this.showDeleteModal = false;
        this.loadingBtnDelete = false;
      }
    },
    openModalEdit(item) {
      this.isOpenModalEdit = true;
      this.editMonedaItem = item;
    },
    goToCotizacionesMonedas() {
      this.$router.push({
        name: "CotizacionesMonedas",
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true,
      });
    },
    closeAndReload() {
      this.isOpenModalEdit = false;
      this.loadMonedas();
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>